<script setup>

</script>

<template>

    <div>
        <p class="text-xs mt-2">
            Datos de contacto: Puede ejercer sus derechos de protección de datos dirigiéndose a FUNDACION CAROLINA,
            C/ Serrano Galvache, 26 Torre Sur, 3ª planta - 28033 Madrid (MADRID), España o al correo electrónico <a class="text-blue-800" href="mailto:protecciondedatos@fundacioncarolina.es">protecciondedatos@fundacioncarolina.es</a>.
        </p>
        <p class="text-xs mt-2">
            Más información: <a class="text-blue-800" target="_blank" href="/docs/politica_privacidad_fc_v1.pdf">Política de privacidad</a>
        </p>
    </div>

</template>

